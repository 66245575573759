import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthRequestService } from './auth-request.service';
import { AuthStateService } from './auth-state.service';
import { filter, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private authRequestService: AuthRequestService,
    private authStateService: AuthStateService,
    private router: Router
  ) { }

  login() {
    const returnURL = btoa(encodeURIComponent(window.location.origin + '?type=login'));
    const redirectURL = `${environment.authModuleUrl}/signin?returnURL=${returnURL}&theme=dark`;

    window.location.href = redirectURL;
  }

  refreshToken(refreshToken: string) {
    return this.authRequestService.refreshTokenRequest(refreshToken).pipe(
      filter((response) => !!response.success),
      tap((response) => {
        this.authStateService.setAccessToken(response.data!.accessToken);
        this.authStateService.setRefreshToken(response.data!.refreshToken);
      })
    );
  }

  logout() {
    this.authStateService.clearData();
    this.router.navigate(['auth/login']);
  }
}
