import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthStateService {
  private accessTokenSrc = new BehaviorSubject<string>(localStorage.getItem('accessToken') || '');
  private refreshTokenSrc = new BehaviorSubject<string>(localStorage.getItem('refreshToken') || '');

  isLoggedIn$ = this.accessTokenSrc.pipe(map((token) => !!token));
  get isLoggedIn(): boolean {
    return !!this.accessTokenSrc.value;
  }

  // Observable getters
  getAccessToken$(): Observable<string> {
    return this.accessTokenSrc.asObservable();
  }

  // Synchronous value getters
  get accessTokenSnapshot(): string {
    return this.accessTokenSrc.value;
  }

  get refreshTokenSnapshot(): string {
    return this.refreshTokenSrc.value;
  }

  // Setters
  setAccessToken(token: string) {
    localStorage.setItem('accessToken', token);
    this.accessTokenSrc.next(token);
  }

  setRefreshToken(token: string) {
    localStorage.setItem('refreshToken', token);
    this.refreshTokenSrc.next(token);
  }

  clearData() {
    this.setAccessToken('');
    this.setRefreshToken('');
  }
}
