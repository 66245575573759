import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStateService } from '../../shared/services/auth/auth-state.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ngx-home',
  template: '',
})
export class HomeComponent implements OnInit {

  constructor(
    private router: Router,
    private authStateService: AuthStateService,
  ) { }

  ngOnInit(): void {
    this.authStateService.isLoggedIn$
      .pipe(
        tap(isLoggedIn => {
          if (isLoggedIn) {
            this.router.navigate(['pages']);
          } else {
            this.router.navigate(['auth/login']);
          }
        })
      )
      .subscribe();
  }
}
