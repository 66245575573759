import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthRequestService {
  public refreshEndpoint = `/auth/token/refresh`;

  constructor(
    private http: HttpClient
  ) { }

  refreshTokenRequest(refreshToken: string) {
    return this.http.post<Response<any>>(`${environment.authModuleApi}${this.refreshEndpoint}`,
      {
        refreshToken
      },
      {
        headers: new HttpHeaders().set('skipAuth', 'true')
      }
    );
  }
}
