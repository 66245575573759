<div class="header-container">
  <img class="logo" src="../../../../assets/images/gaimin-logo.png" alt="Dashboard" (click)="navigateHome()" />
  <nb-icon icon="menu-outline" (click)="toggleSidebar()"></nb-icon>
</div>
<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action">
      <nb-user
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="(userData | async)?.nickName"
        [picture]="(userData | async)?.avatarUrl"
        >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
