import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpResponse, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { NbToastrService } from '@nebular/theme';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import packageData from '../../../../package.json';
import { AuthService } from '../services/auth/auth.service';
import { AuthRequestService } from '../services/auth/auth-request.service';
import { AuthStateService } from '../services/auth/auth-state.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject = new BehaviorSubject<string | null>(null);

  constructor(
    private toastrService: NbToastrService,
    private authService: AuthService,
    private authRequestService: AuthRequestService,
    private authStateService: AuthStateService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (request.url.includes(environment.gaiminApi)) {
      request = request.clone({
        headers: request.headers
          .set('X-Client-Version', `backoffice\\${packageData.version}`)
          .set('Accept-Language', `en_US`)
      });
    }

    return next.handle(request).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse && (request.url.includes(environment.gaiminApi) || request.url.includes(environment.colosseumApi))) {
          if (!(evt.body instanceof Blob) && !evt?.body.success) {
            const isAuthenticationError = evt?.body.error.type === 'AUTHENTICATION';

            if (!isAuthenticationError) {
              this.toastrService.danger(
                `Error: ${evt?.body.error.type}`,
                `Something went wrong! ${evt?.body.error.description}`
              );
            }
          }
        }
      }),
      switchMap((event: HttpEvent<any>) => {
        if (!(event instanceof HttpResponse) || event.body == null || event.body.success) {
          return [event];
        }

        const errorType = event.body.error?.type;

        if (
          event.url?.includes(this.authRequestService.refreshEndpoint) &&
          (errorType === 'AUTHORIZATION' || errorType === 'AUTHENTICATION')
        ) {
          this.authService.logout();
          return [event];
        }

        if (errorType === 'AUTHENTICATION' && !request.url.includes('roles')) {
          return this.handleAuthenticationError(request, next);
        }

        return [event];
      }),
      catchError((error): any => {
        console.error('Error:', error);
        this.toastrService.danger(`Error: ${error?.error?.error}`, `Something went wrong!`);
      })
    );
  }

  private handleAuthenticationError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const refreshToken = this.authStateService.refreshTokenSnapshot;
      if (refreshToken) {
        return this.authService.refreshToken(refreshToken).pipe(
          switchMap((response) => {
            if (response.data) {
              this.refreshTokenSubject.next(response.data.accessToken);
              this.isRefreshing = false;
              return next.handle(request);
            } else {
              this.authService.logout();
              return throwError(() => new HttpErrorResponse({ status: 401 }));
            }
          }),
          catchError((error) => {
            this.isRefreshing = false;
            this.authService.logout();
            return throwError(() => error);
          })
        );
      } else {
        this.authService.logout();
        return throwError(() => new HttpErrorResponse({ status: 401 }));
      }
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap(() => next.handle(request))
      );
    }
  }
}
