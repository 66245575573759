import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { UserBase, Response } from '../../interfaces';
import { tap } from 'rxjs/operators';
import { USER_ROLES } from '../../enums';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSrc$ = new BehaviorSubject<UserBase | null>(null);
  public user$ = this.userSrc$.asObservable();

  private currentUserRolesSrc$ = new BehaviorSubject<string[]>([]);
  public currentUserRoles$: Observable<string[]> = this.currentUserRolesSrc$.asObservable();

  constructor(private http: HttpClient) { }

  checkUserRights(userRoles: string[]): boolean {
    return userRoles.some((roleToCheck) => this.currentUserRolesSrc$.getValue()?.includes(roleToCheck));
  }

  meRequest() {
    return this.http
      .get<Response<UserBase>>(`${environment.gaiminApi}/users/me`)
      .pipe(
        tap(response => {
          if (response?.data && response.success) {
            this.currentUserRolesSrc$.next(response.data.roles);
            this.userSrc$.next(response.data);
          }
        })
      )
  }
}
